<template>
  <div class="main">
    <span style="text-transform: capitalize">
      {{
        (
          elementConf.displayName ||
          elementConf.name ||
          'untitled element-' + dIdx
        ).replace(/_/g, ' ')
      }}</span
    >
    <v-tooltip bottom v-if="showSaveBtn">
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-on="on"
          v-bind="attrs"
          style="float: right; color: green"
          @click="saveOutboundParams()"
          >mdi-cloud-upload</v-icon
        >
      </template>
      <span>Save Out Bound Params</span>
    </v-tooltip>
    <v-divider></v-divider>
    <div class="params-list">
      <div class="element-params">
        <template
          v-for="(params, paramsId) of drillConf.extractParams.fromElement"
        >
          <div :key="paramsId" class="param">
            <span>{{ paramsId }} </span>
            <v-icon>mdi-arrow-right</v-icon>
            <input
              :value="params || paramsId"
              class="input-params"
              @input="onchangeParams($event, 'fromElement', paramsId)"
            />
          </div>
        </template>
      </div>
      <div class="layout-params">
        <template
          v-for="(params, paramsId) of drillConf.extractParams.fromLayout"
        >
          <div :key="paramsId" class="param">
            <span>{{ paramsId }} </span>
            <v-icon>mdi-arrow-right</v-icon>
            <input
              :value="params || paramsId"
              class="input-params"
              @input="onchangeParams($event, 'fromLayout', paramsId)"
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['elementId', 'drillConf', 'drillLayoutId', 'dIdx'],
  data() {
    return {
      paramsMapped: { fromElement: {}, fromLayout: {} },
      showSaveBtn: false
    }
  },
  computed: {
    elementConf() {
      return this.$store.state.allElementsForImport.find(
        (el) => el._id == this.drillConf.elementId
      )
    }
  },
  methods: {
    onchangeParams($event, type, key) {
      console.log({ value: $event.target.value, type, key })
      this.paramsMapped[type][key] = $event.target.value
      this.showSaveBtn = true
    },
    async saveOutboundParams() {
      // console.log(`[this.paramsMapped]: `, this.paramsMapped)
      let drill = { ...this.drillConf }
      drill.extractParams = this.paramsMapped
      drill.layoutId = this.drillLayoutId
      console.log(`[drill]: `, drill)
      this.$store.commit('loader', true)
      await this.$store
        .dispatch('ReportBuilder/setOutBoundParams', drill)
        .then()
      this.$store.commit('loader', false);
      this.showSaveBtn = false;
    }
  },
  created() {
    console.log(`[this.dIdx]: `, this)
    this.paramsMapped = { ...this.drillConf.extractParams }
  }
}
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
}
.input-params:focus {
  border: 1px solid #3f51b5 !important;
  outline: #3f51b5;
}
.input-params {
  width: fit-content;
  border-radius: 10px;
  padding-left: 10px;
  color: royalblue;
}
.params-list {
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.param {
  margin-bottom: 5px;
}
</style>